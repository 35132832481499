module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":704}},{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false}},"gatsby-remark-embed-video",{"resolve":"gatsby-remark-plantuml-lite","options":{"imageType":"svg","server":"https://www.plantuml.com/plantuml"}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/joi/Documents/candyhouse/online_document"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Document","short_name":"Document","start_url":"/","background_color":"#182952","theme_color":"#a2466c","display":"standalone","icon":"src/site-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"242f0bd1bb99891153d6d05d0233977d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
