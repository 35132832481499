export default {
  text: "#000",
  background: "#fff",
  primary: "#28aeb1",
  secondary: "#68c6c8",
  sidebar: "#eee",
  borderColor: "rgba(0, 0, 0, 0.15)",
  modes: {
    dark: {
      text: "#bbb",
      background: "#182952",
      primary: "#28aeb1",
      secondary: "#68c6c8",
      sidebar: "#101d3c",
      borderColor: "rgba(255, 255, 255, 0.15)"
    }
  }
};
